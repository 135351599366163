import React, { useState, useEffect } from "react"
import Layout from "../../../components/Layout/layout"
import People from "./people"
import Details from "./details"

import "bootstrap/dist/css/bootstrap.min.css"
import "../../../css/swiftcloud_global.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "../../Layout/menu.css"
import "../../../css/jquery.dataTables.min.css"
import "bootstrap-select/dist/css/bootstrap-select.css"
import "../../../css/bootcomplete.css"
import "../../../css/jquery.datetimepicker.css"
import "../../../css/jquery.datetimepicker.css"
import "../../../css/chat-dock.css"
import "../../../css/accounting.css"
import "../../../css/create_invoice.css"
import AxiosInstance from "../../../services/axiosInstance"
import { getUserName } from "../Doc/Service/docHelper"
import { getCurrencies, getLanguages } from "../../Global/apiHelpers"
import InvoiceModal from "../Popups/invoiceModal"
import { Link } from "gatsby"

const Invoice = ({ fileId }) => {
  const [copyPeoples, setCopyPeoples] = useState([]),
    [invoiceTitle, setInvoiceTitle] = useState("Untitled Invoice"),
    [payor, setPayor] = useState({}),
    [comState, setComState] = useState({
      saveData: false,
      upToDate: { moreOptions: false, ueFlow: false },
      level: 0,
    }),
    [states, setStates] = useState({
      type: "invoice",
      status: "due",
      paymentMethod: "",
      showCommitmentStatus: false,
      breakMilestonePaymentPlan: "0",
      allPartialPayment: "0",
      committedOrNot: "notCommitted",
      chargeOrPayment: "Charge",
      payMethods: [],
      notes: "",
      footerNote: "",
      paymentPlanOptions: "2",
      taxRate: 0.0,
      taxType: "1",
      finalTotal: 0.0,
      form: {
        moreOptions: {},
        ueFlow: {},
        saveData: 0,
      },
      dhAuto: [],
      driveType: "invoice",
      showCreditCardModal: false,
    }),
    [items, setItems] = useState([]),
    [options, setOptions] = useState({
      payment: 0,
      discount: 0,
      shipping: 0,
      installation: 0,
      account: 0,
    }),
    [payee, setPayee] = useState({}),
    [invoiceConsts, setInvoiceConsts] = useState({}),
    [selectedDocId, setSelectedDocId] = useState(0)

  useEffect(async () => {
    const invoiceDetailResponse = await AxiosInstance.get(`/drive/invoice/details/${fileId}`)
    if (invoiceDetailResponse.status == 200) {
      setInvoiceData(invoiceDetailResponse.data.driveInvoice)
    }
  }, [])

  const updateTitle = e => {
    setInvoiceTitle(e.target.value)
  }

  const setInvoiceData = async invoiceDetails => {
    const { ownerID, parentID } = invoiceDetails.driveAll
    setInvoiceConsts({
      ...invoiceConsts,
      ownerID,
      parentID,
      driveId: invoiceDetails.driveAll.id,
      fileUrl: fileId,
      slug: invoiceDetails.driveAll.slug,
    })
    setInvoiceTitle(invoiceDetails.invoiceName)
    setCopyPeoples(invoiceDetails?.content?.copyPeoples ?? [])
    setPayor(invoiceDetails?.content?.payor ?? {})
    setPayee(invoiceDetails?.content?.payee ?? {})
    setItems(invoiceDetails.content.items ?? [])
    setSelectedDocId(invoiceDetails.driveAll.id)
    const { payment, discount, shipping, installation, account } = invoiceDetails.content
    setOptions({
      payment,
      discount,
      shipping,
      installation,
      account,
    })
    const { driveAll, ...form } = invoiceDetails.driveForm
    setStates({
      ...states,
      notes: invoiceDetails.content.notes,
      footerNote: invoiceDetails.content.footerNote,
      chargeOrPayment: invoiceDetails.content.chargeOrPayment,
      payMethods: invoiceDetails.content.payMethods,
      allPartialPayment: invoiceDetails.content.allPartialPayment,
      breakMilestonePaymentPlan: invoiceDetails.content.breakMilestonePaymentPlan || "0",
      paymentPlanOptions: invoiceDetails.content.paymentPlanOptions ?? 1,
      committedOrNot: invoiceDetails.content.committedOrNot,
      taxRate: invoiceDetails.content.taxRate,
      taxType: invoiceDetails.content.taxType,
      type: invoiceDetails.invoiceType,
      status: invoiceDetails.invoiceStatus,
      form: form,
      dhAuto: (Array.isArray(form.dhAutomation) ? form.dhAutomation : [form.dhAutomation]) || [],
      ezLinkDetail: form.ezLinkDetail || {},
      showForThemPanel: Object.keys(form?.ueFlow || []).length !== 0,
      showDhaPanel: Object.keys(form?.dhAutomation || []).length !== 0,
      showInstallationPanel: Object.keys(form?.ezLinkDetail || []).length !== 0,
      showMoreDetailsPanel: Object.keys(form?.moreOptions || []).length !== 0,
      currencies: await getCurrencies(),
      languages: await getLanguages(),
      objectId: invoiceDetails.id,
      driveId: invoiceDetails.driveAll.id,
      driveSlug: invoiceDetails.driveAll.slug,
      dhaIntroText: "After Payment please…",
    })
  }

  useEffect(async () => {
    if (comState.saveData && comState.level === 1) {
      await saveInvoice()
      setComState({ saveData: false, upToDate: { moreOptions: false, ueFlow: false }, level: 0 })
    }
  }, [comState.saveData, comState.upToDate, comState.level])

  const triggerSave = () => {
    setComState({ ...comState, saveData: true })
  }

  const saveInvoice = async () => {
    try {
      let { type, status, form, dhAuto, ezLinkDetail, ...content } = states
      content = {
        ...content,
        items,
        payor,
        payee,
        copyPeoples,
        ...options,
      }
      const invoiceData = {
        invoiceName: invoiceTitle,
        content,
        form: { ...form, dhAutomation: dhAuto, ezLinkDetail },
        invoiceType: type,
        invoiceStatus: status,
        ...invoiceConsts,
      }
      const saveResponse = await AxiosInstance.post(`/drive/invoice/edit/${fileId}`, invoiceData)
    } catch (error) {
      console.error("Error when updating invoice:", error.message)
    }
  }

  return (
    <Layout>
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col" id="page-urls">
            <div className="row g-0">
              <div className="col-sm-12 mb-3">
                <div className="pageTitle_Editor">
                  <Link href="/drive" className="goBack">
                    <i className="bi bi-arrow-90deg-left"></i>
                  </Link>
                  <button type="button" className="CD_UserType isHuman" data-bs-toggle="tooltip">
                    <i className="bi bi-list-ul" />
                  </button>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Untitled Invoice"
                    value={invoiceTitle}
                    onChange={updateTitle}
                  />
                </div>
              </div>
            </div>

            <div className="row g-0">
              <div className="col-lg-12">
                <div className="row g-0" id="taskDetailTemplate">
                  {/*  People & Permissions */}
                  <People
                    payee={payee}
                    setPayee={setPayee}
                    payor={payor}
                    setPayor={setPayor}
                    copyPeoples={copyPeoples}
                    setCopyPeoples={setCopyPeoples}
                  />

                  {/* Invoice Panel */}
                  <Details
                    items={items}
                    setItems={setItems}
                    states={states}
                    setStates={setStates}
                    options={options}
                    setOptions={setOptions}
                    fileId={fileId}
                    selectedDocId={selectedDocId}
                    invoiceConsts={invoiceConsts}
                    comState={comState}
                    setComState={setComState}
                    payor={payor}
                  />

                  <div className="col-lg-12 text-center">
                    <div className="btn-group btnAddNewDropdown">
                      <button type="button" onClick={triggerSave} className="btn btn-primary btn-lg my-1">
                        <i className="bi bi-check2"></i> Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-lg my-1 dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="visually-hidden">Toggle Dropdown</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-files"></i> Save As Copy
                          </a>
                        </li>
                      </ul>
                    </div>{" "}
                    <a
                      href={`${process.env.GATSBY_PREVIEW_URL}/${getUserName()}/invoice/${fileId}`}
                      type="button"
                      className="btn btn-secondary btn-lg"
                      target="_blank"
                    >
                      <i className="bi bi-box-arrow-up-right" /> Preview
                    </a>{" "}
                    <div className="btn-group btnAddNewDropdown btnSendWithSequence">
                      <button
                        type="button"
                        className="btn btn-magic btn-lg my-1 btnOpenChatDockEmail"
                        data-type="Email"
                        data-value="amitabh@domain.com"
                      >
                        <i className="bi bi-send-fill"></i> Send
                      </button>
                      <button
                        type="button"
                        className="btn btn-magic btn-lg my-1 dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="visually-hidden">Toggle Dropdown</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item btnCopyInvoiceURL"
                            role="button"
                            tabIndex={0}
                            data-bs-toggle="tooltip"
                            title="Copy"
                          >
                            <i className="bi bi-files"></i> Copy Link
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InvoiceModal />
      </div>
    </Layout>
  )
}

export default Invoice
