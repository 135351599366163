import React, { useState, useEffect } from "react"
import { getUserName } from "../Doc/Service/docHelper"

const InstallationOptions = ({ states, setStates }) => {
  const [invId, setInvId] = useState("")
  const username = getUserName()

  useEffect(() => {
    setInvId(`${localStorage.getItem("user_id")}I${states.objectId}`)
  }, [states.objectId])

  const doNothing = e => {
    e.preventDefault()
  }

  return (
    <div className="col-lg-12 mb-3" id="installation_panel">
      <div className="accordion" id="installationPanelAccordion">
        <div className="accordion-item panel panel-beige">
          <div className="accordion-header">
            <div
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target="#installation_container"
              aria-expanded="true"
              aria-controls="installation_container"
            >
              <span className="panel-heading-icon">
                <i className="bi bi-megaphone-fill"></i>
              </span>
              <span className="panel-heading-title">Using your invoice {invId}</span>
            </div>
          </div>
          <div
            id="installation_container"
            className="accordion-collapse collapse show"
            aria-labelledby="installation_container"
          >
            <div className="accordion-body">
              <strong>Here is your URL</strong>

              <div className="customURLContainer py-5 text-center">
                <a className="IO_OriginalURL dashedLinked" href="#">
                  {process.env.GATSBY_PREVIEW_URL}/{username}/invoice/{invId}
                </a>
                <input
                  type="text"
                  id="hiddenInvoicePreviewURL"
                  defaultValue={`${process.env.GATSBY_PREVIEW_URL}/${username}/invoice/${invId}`}
                />
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <ul className="IO_Action_Btns">
                    <li>
                      <a href="#" onClick={doNothing} className="">
                        <i className="bi bi-person-fill-check"></i> Request Completion
                      </a>
                    </li>

                    {/* <!-- OPEN URL EDITOR GLOBAL --> */}
                    <li>
                      <a href="#Global_URLInjector" onClick={doNothing} data-bs-toggle="modal">
                        <i className="bi bi-graph-up-arrow"></i> Tracking URL
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-12 pt-5 IOShareContainer">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-share-fill"></i> Share
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <div className="row">
                      <div className="col-lg-12 text-center shareAction colAction">
                        <ul>
                          <li className="black-bg">
                            <a
                              href="#"
                              onClick={doNothing}
                              className="btnGlobalURLQRCode"
                              data-bs-toggle="tooltip"
                              title="Generate QR Code"
                            >
                              <i className="bi bi-qr-code"></i>
                            </a>
                          </li>
                          <li className="paper-plane-bg">
                            <a
                              data-bs-toggle="tooltip"
                              title="Email This Page"
                              href="mailto:?subject=You would like this&amp;body=Hello%0A%0AYou%20will%20like%20this%3A%0Ahttps://click.SwiftCRM.com/{username}/AutoGenButAllowInputOverride"
                              target="_blank"
                            >
                              <i className="bi bi-envelope-fill"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstallationOptions
