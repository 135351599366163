import React from "react"
import { getSelectOptions } from "../../Item/helpers"

const OtherOptions = ({ states, setStates }) => {
  const updateData = (fieldName, value) => {
    setStates({
      ...states,
      form: { ...states.form, moreOptions: { ...states.form?.moreOptions, [fieldName]: value } },
    })
  }

  const updateLateFeeStatus = e => {
    const value = e.target.checked ? "1" : "0"
    updateData("lateFeeOpt", value)
  }

  const updateAddAmount = e => {
    updateData("addAmount", e.target.value)
  }

  const updateWithinDays = e => {
    updateData("withDays", e.target.value)
  }

  const updateCurrency = e => {
    updateData("currency", e.target.value)
  }

  const updateLanguage = e => {
    updateData("invoiceLanguage", e.target.value)
  }

  const updateAgreementAutoInvoicing = e => {
    const value = e.target.checked ? "1" : "0"
    updateData("agreementAutoInvoicing", value)
  }

  const updateAgreements = e => {
    updateData("agreements", e.target.value)
  }

  return (
    <>
      <div className="col-lg-12 mb-3" id="more_panel">
        <div className="accordion" id="morePanelAccordion">
          <div className="accordion-item panel panel-grey">
            <div className="accordion-header">
              <div
                className="accordion-button"
                data-bs-toggle="collapse"
                data-bs-target="#more_container"
                aria-expanded="true"
                aria-controls="more_container"
              >
                <span className="panel-heading-icon">
                  <i className="bi bi-three-dots"></i>
                </span>
                <span className="panel-heading-title">More Options</span>
              </div>
            </div>
            <div id="more_container" className="accordion-collapse collapse" aria-labelledby="more_container">
              <div className="accordion-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="colHeading">
                          <strong>
                            <i className="bi bi-stopwatch"></i> Auto Late Fee Escalation
                          </strong>
                        </div>
                        <div className="dh-block-body">
                          <div className="row row-cols-lg-auto g-2 align-items-center">
                            <div className="col-12">Auto Late Fee is</div>
                            <div className="col-12">
                              <div className="toggleSwitch">
                                <input
                                  type="checkbox"
                                  value={1}
                                  name="lateFeeOpt"
                                  id="lateFeeOpt"
                                  checked={states.form?.moreOptions?.lateFeeOpt === "1"}
                                  onChange={updateLateFeeStatus}
                                />
                                <label></label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3 lateFeeOpt">
                            <div className="col-lg-12">
                              <div className="row row-cols-lg-auto g-2 align-items-center">
                                <div className="col-12">Add</div>
                                <div className="col-12">
                                  <div className="input-group my-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="addMonthAmount"
                                      id="addMonthAmount"
                                      value={states.form?.moreOptions?.addAmount || 0}
                                      maxLength="3"
                                      onChange={updateAddAmount}
                                    />
                                    <div className="input-group-text">%</div>
                                  </div>
                                </div>
                                <div className="col-12">per month to any amount not paid within</div>
                                <div className="col-12">
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="within_days"
                                    id="within_days"
                                    onChange={updateWithinDays}
                                    maxLength="3"
                                    value={states.form?.moreOptions?.withinDays || 30}
                                  />
                                </div>
                                <div className="col-12">days of invoice.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="colHeading">
                          <strong>
                            <i className="bi bi-currency-rupee"></i> Currency
                          </strong>
                        </div>
                        <div className="dh-block-body">
                          <div className="row">
                            <div className="col-12">
                              <select
                                name="currency"
                                className=" form-select"
                                value={states.form?.moreOptions?.currency || ""}
                                onChange={updateCurrency}
                              >
                                <option value="0">--Select Currency--</option>
                                {getSelectOptions(states, "currencies", "name", "code")}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="colHeading">
                          <strong>
                            <i className="bi bi-arrow-repeat"></i> Subscriptions &amp; Agreements
                            Auto-Invoicing
                          </strong>
                        </div>
                        <div className="dh-block-body">
                          <div className="row row-cols-sm-auto g-2 align-items-center">
                            <div className="col-12">This invoice is part of a subscription or agreement</div>
                            <div className="col-12">
                              <div className="toggleSwitch">
                                <input
                                  type="checkbox"
                                  value={1}
                                  name="agreementAutoInvoicing"
                                  id="agreementAutoInvoicing"
                                  checked={states.form?.moreOptions?.agreementAutoInvoicing === "1"}
                                  onChange={updateAgreementAutoInvoicing}
                                />
                                <label></label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3 agreementAutoInvoicing display-none">
                            <div className="col-lg-12">
                              <select
                                className="form-select"
                                value={states.form?.moreOptions?.agreements || ""}
                                onChange={updateAgreements}
                              >
                                <option value="">AGREEMENTS WITH GREENBOX CONTACTS SHOW HERE</option>
                                {/** Load all existing agreeements */}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="colHeading">
                          <strong>
                            <i className="bi bi-globe"></i> Language
                          </strong>
                        </div>
                        <div className="dh-block-body">
                          <select
                            name="invoiceLanguage"
                            className="form-select"
                            value={states.form?.moreOptions?.invoiceLanguage || "default"}
                            onChange={updateLanguage}
                          >
                            <option value="default">Default Language</option>
                            {states.languages.map(language => {
                              return (
                                <option value={language.code} key={language.id}>
                                  {language.language}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OtherOptions
